const baseUrls = {
  baseUrl: process.env.REACT_APP_API_URL!,
  baseUrlSettings: process.env.REACT_APP_API_URL_SETTINGS!,
  baseUrlSatisfacao: process.env.REACT_APP_API_PESQUISA_SATISFACAO!,
};

export const tenantKey: Record<
string,
{
  id: number;
  baseUrl: string;
  baseUrlSettings: string;
  baseUrlSatisfacao: string;
} | undefined
> = {
  acacia: { id: 66, ...baseUrls },
  actus: { id: 102, ...baseUrls },
  albertsabin: { id: 100, ...baseUrls },
  almeida: { id: 79, ...baseUrls },
  amg: { id: 76, ...baseUrls },
  amigodosbichos: { id: 40, ...baseUrls },
  antonelli: { id: 85, ...baseUrls },
  arcaro: { id: 104, ...baseUrls },
  barrigaverde: { id: 34, ...baseUrls },
  bellavita: { id: 91, ...baseUrls },
  bioanalises: { id: 41, ...baseUrls },
  biogene: { id: 97, ...baseUrls },
  biolab: { id: 56, ...baseUrls },
  biolabgaropaba: { id: 69, ...baseUrls },
  biolabmallet: { id: 63, ...baseUrls },
  biovida: { id: 64, ...baseUrls },
  biovita: { id: 68, ...baseUrls },
  bioviver: { id: 83, ...baseUrls },
  bomjesussc: { id: 31, ...baseUrls },
  cdc: { id: 103, ...baseUrls },
  cdl: { id: 17, ...baseUrls },
  centermais: { id: 93, ...baseUrls },
  cepac_perfil: { id: 49, ...baseUrls },
  cienciasc: { id: 57, ...baseUrls },
  cmp_sc: { id: 119, ...baseUrls },
  continente_perfil: { id: 25, ...baseUrls },
  coralmeleiro: { id: 116, ...baseUrls },
  coralmg: { id: 117, ...baseUrls },
  cpde: { id: 14, ...baseUrls },
  dalpont: { id: 27, ...baseUrls },
  dellasc: { id: 75, ...baseUrls },
  diagnostico: { id: 23, ...baseUrls },
  diagnosticohr: { id: 24, ...baseUrls },
  dralbertsabin: { id: 107, ...baseUrls },
  efilab_perfil: { id: 38, ...baseUrls },
  elisasc: { id: 15, ...baseUrls },
  espindula: { id: 81, ...baseUrls },
  fatima: { id: 26, ...baseUrls },
  ferrarisc: { id: 36, ...baseUrls },
  gabevida: { id: 42, ...baseUrls },
  hemovida: { id: 80, ...baseUrls },
  horaciofigueiredo: { id: 106, ...baseUrls },
  icara: { id: 58, ...baseUrls },
  imunizaram: { id: 121, ...baseUrls },
  italab: { id: 59, ...baseUrls },
  labacgrj: { id: 73, ...baseUrls },
  laban: { id: 29, ...baseUrls },
  labcadpb: { id: 70, ...baseUrls },
  labfey: { id: 46, ...baseUrls },
  labmedmanaus: { id: 21, ...baseUrls },
  labmedsc: { id: 74, ...baseUrls },
  labnorte: { id: 67, ...baseUrls },
  laboanalises: { id: 92, ...baseUrls },
  laboralli: { id: 43, ...baseUrls },
  laborclinico: { id: 13, ...baseUrls },
  laborvida: { id: 51, ...baseUrls },
  labpadrao: { id: 53, ...baseUrls },
  labpop: { id: 112, ...baseUrls },
  labsca: { id: 35, ...baseUrls },
  labvida: { id: 65, ...baseUrls },
  lamuph: { id: 31, ...baseUrls },
  lanac: {
    id: 18,
    ...baseUrls,
    baseUrl: 'https://lanaccloud.bitlabenterprise.com.br/API.Bitlab',
    baseUrlSettings: 'https://lanaccloud.bitlabenterprise.com.br/API.Settings',
    baseUrlSatisfacao: 'https://lanaccloud.bitlabenterprise.com.br/API.PesquisaSatisfacao',
  },
  lealprado: { id: 78, ...baseUrls },
  luizpirespi: { id: 110, ...baseUrls },
  maissaude: { id: 105, ...baseUrls },
  mariaschmitt: { id: 108, ...baseUrls },
  marista: { id: 61, ...baseUrls },
  maxisaude: { id: 88, ...baseUrls },
  medeiros: { id: 84, ...baseUrls },
  meninodeus: { id: 33, ...baseUrls },
  michels: { id: 101, ...baseUrls },
  microlab: { id: 20, ...baseUrls },
  milclin: { id: 90, ...baseUrls },
  neurogene: { id: 86, ...baseUrls },
  nsfituporanga: { id: 87, ...baseUrls },
  pasteursc: { id: 47, ...baseUrls },
  policlinicasc: { id: 95, ...baseUrls },
  preve: { id: 50, ...baseUrls },
  prosphera: { id: 82, ...baseUrls },
  rafael: { id: 22, ...baseUrls },
  reunidos: { id: 32, ...baseUrls },
  santacruz: { id: 16, ...baseUrls },
  santalurdes: { id: 77, ...baseUrls },
  santaterezinhapr: { id: 142, ...baseUrls },
  santaveronica: { id: 55, ...baseUrls },
  sante: { id: 62, ...baseUrls },
  saofrancisco: { id: 113, ...baseUrls },
  saogeronimo: { id: 28, ...baseUrls },
  saojoao: { id: 44, ...baseUrls },
  saolucas: { id: 48, ...baseUrls },
  saolucassjb: { id: 94, ...baseUrls },
  saoluizturvo: { id: 72, ...baseUrls },
  saosebastiao: { id: 111, ...baseUrls },
  scribner: { id: 19, ...baseUrls },
  seemann: { id: 52, ...baseUrls },
  setemed: { id: 109, ...baseUrls },
  sinimbu: { id: 99, ...baseUrls },
  spilleresc: { id: 30, ...baseUrls },
  teste: { id: 118, ...baseUrls },
  testebetha: { id: 120, ...baseUrls },
  unidos: { id: 60, ...baseUrls },
  unoesc: { id: 96, ...baseUrls },
  venancio: { id: 89, ...baseUrls },
  vidaesaude: { id: 45, ...baseUrls },
  vitalabsc: { id: 134, ...baseUrls },
  westrupp: { id: 54, ...baseUrls },
  zanattasc: { id: 114, ...baseUrls },
  comercial: { id: 135, ...baseUrls },
};
